<template>
    <div class="m-0 px-3 pt-4 pb-0 pr-2">
        <back-button class="ml-4" />
        <div class="ml-2 d-flex justify-content-between">
            <h1>{{ 'Edit Baritastic Program' }}</h1>
        </div>
        <baritastic-program-form ref="form" :baritastic-program="baritasticProgram" @update="update" form-type="update"
            :loader="loaders.fetch || loaders.submit" />
    </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import BaritasticProgramForm from "@/views/Components/SuperAdmin/BaritasticProgram/Form";
export default {
    mixins: [generateErrorMessageMixin],
    components: {
        BaritasticProgramForm,
        BackButton
    },
    data() {
        return {
            loaders: {
                submit: false,
                fetch: false
            },
            baritasticProgram : null,
        };
    },
    mounted(){
        this.getBaritasticProgram()
    },
    methods: {
        async getBaritasticProgram() {
            let vm = this;
            vm.loaders.program = true
            const payload = {
                id: this.$route.params.id,
            }
            try {
                let response = await vm.$store.dispatch('BaritasticProgramModule/_getRequestedBaritasticProgram', payload);
                vm.baritasticProgram = response.data.data

            } catch (error) {
                if (error?.response?.status == '404') {
                    this.$router.push({ name: 'admin.baritastic_program' })

                }
            }
            vm.loaders.program = false

        },
        async update({ fd, id }) {
            let validation = this.$refs.form.$refs.formValidator;
            this.loaders.submit = true;
            try {
                let response = await this.$store.dispatch('BaritasticProgramModule/_updateBaritasticProgram', { fd, id });
                let { data: { data, message } } = response;
                this.$notify.success({
                    title: 'Baritastic Program',
                    dangerouslyUseHTMLString: true,
                    message: message
                });
                this.$router.push({ name: 'admin.baritastic_program' })
            } catch (error) {
                this.showErrorOnForm(error, 'Baritastic Program', validation)
            }
            this.loaders.submit = false;
        }
    },
};
</script>
  
<style scoped lang="scss"></style>
  